import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Откройте для себя хаб Bike Bliss
			</title>
			<meta name={"description"} content={"Погрузитесь в историю каждой педали - Bike Bliss Hub, где создаются велосипедные истории"} />
			<meta property={"og:title"} content={"О нас | Откройте для себя хаб Bike Bliss"} />
			<meta property={"og:description"} content={"Погрузитесь в историю каждой педали - Bike Bliss Hub, где создаются велосипедные истории"} />
			<meta property={"og:image"} content={"https://bakuriser.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bakuriser.com/img/35235235.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bakuriser.com/img/35235235.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bakuriser.com/img/35235235.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bakuriser.com/img/35235235.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bakuriser.com/img/35235235.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bakuriser.com/img/35235235.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						О нас
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Bike Bliss Hub - это маяк для энтузиастов велосипедного спорта и обычных велосипедистов, воплощающий в себе стремление к созданию радости, сообщества и непревзойденного сервиса в мире велоспорта. Наша философия проста: каждый велосипед рассказывает свою историю, а каждая поездка - это возможность добавить новую главу.
					</Text>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box width="100%">
					<Image src="https://bakuriser.com/img/3.jpg" width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://bakuriser.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Дух Bike Bliss Hub
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Зародившись из страсти к велоспорту и желания создать гостеприимное пространство для всех велосипедистов, Bike Bliss Hub - это больше, чем сервисный центр, это сообщество. Не вдаваясь в подробности о происхождении и основателях, мы сосредоточились на том, что действительно важно: радости от езды на велосипеде и связях, которые она укрепляет. Наш центр - это свидетельство коллективного духа велосипедистов, место, где встречаются опыт, забота и энтузиазм.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
			<Text margin="0px 0px 50px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Почему наша сущность находит отклик
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="0 40px"
				md-grid-template-columns="1fr"
				md-grid-gap="50px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Непревзойденный опыт
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Наши механики - опытные велосипедисты, они предлагают свои услуги, основанные на многолетнем опыте и любви к езде.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Ориентированность на сообщество
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Bike Bliss Hub процветает благодаря связям между велосипедистами. Благодаря мероприятиям, семинарам и групповым поездкам мы укрепляем чувство принадлежности и общей страсти.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Превосходство в обслуживании
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Мы гордимся тем, что не только оправдываем, но и превосходим ожидания, уделяя пристальное внимание деталям, гарантируя, что ваш велосипед всегда будет готов к следующему путешествию.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
								Инновации и традиции
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Балансируя между передовыми технологиями и традиционным мастерством, мы ориентируемся на современного велосипедиста, уважая при этом вечное искусство обслуживания велосипеда.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Присоединяйтесь к нашему велосипедному путешествию
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Мы приглашаем вас стать частью истории Bike Bliss Hub. Готовитесь ли вы к соревновательной гонке, неспешной поездке или чему-то среднему, здесь ваше велосипедное приключение приобретет глубину и радость. Давайте отправимся в это путешествие вместе, создавая незабываемые моменты и истории на двух колесах.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://bakuriser.com/img/5.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});